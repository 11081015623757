import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x411b0fAcC3489691f28ad58c47006AF5E3Ab3A28',
};

export const POOL_DEPLOYER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x2D98E2FA9da15aa6dC9581AB097Ced7af697CB92',
};

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa15F0D7377B2A0C0c10db057f641beD21028FC89',
};

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0xf5b509bB0909a69B1c207E495f687a596C168E12',
};

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x8eF88E4c7CfbbaC1C163f7eddd4B578792201de6',
};

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x6ccb9426CeceE2903FbD97fd833fD1D31c100292',
};

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x157B9913E00204f8c980bb00aa62E22b0dAb1a63',
};

export const FINITE_FARMING_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9923f42a02A82dA63EE0DbbC5f8E311e3DD8A1f8',
};

export const INFINITE_FARMING_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x8a26436e41d0b5fc4C6Ed36C1976fafBe173444E',
};

export const FARMING_CENTER: AddressMap = {
  [SupportedChainId.POLYGON]: '0x7F281A8cdF66eF5e9db8434Ec6D97acc1bc01E78',
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32'
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff'
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x65770b5283117639760beA3F867b69b3697a91dd'
}