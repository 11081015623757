import { ApolloClient, InMemoryCache } from '@apollo/client'

export const healthClient = new ApolloClient({
    uri: 'https://api.thegraph.com/index-node/graphql',
    cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
    uri: 'https://api.fura.org/subgraphs/name/quickswap',
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
})

export const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3',
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
})

export const farmingClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3-farming',
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
})